.sd-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: calcSize(6);
  background-position: right calcSize(1.5) top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: calcSize(3) calcSize(3);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 15L17 10H7L12 15Z' fill='%23909090'/%3E%3C/svg%3E%0A");
  opacity: 1;
  display: flex;
  justify-content: space-between;
}
.sd-dropdown[disabled] {
  pointer-events: none;
}
.sd-dropdown--empty:not(.sd-input--disabled),
.sd-dropdown--empty:not(.sd-input--disabled) .sd-dropdown__value {
  color: $foreground-light;
}
.sd-dropdown__input-field-component {
  height: auto;
}
.sd-dropdown option {
  color: $foreground;
  font-family: $font-family;
  font-size: calcSize(2);
}
.sd-dropdown__value {
  width: 100%;
  min-height: calcSize(3);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: $font-family;
  font-size: calcSize(2);
  color: $foreground;

  position: relative;
}

.sd-dropdown_clean-button {
  padding: 0 calcSize(1);
  margin: auto 0;
}
.sd-dropdown_clean-button-svg {
  width: calcSize(3);
  height: calcSize(3);
}
.sd-dropdown use {
  fill: $foreground-light;
}

.sd-input.sd-dropdown:focus-within {
  box-shadow: 0 0 0 2px $primary, inset 0px 1px 2px $border-inside;
}
.sd-input.sd-dropdown:focus-within {
  .sd-dropdown__filter-string-input {
    z-index: 2000;
  }
}
.sd-dropdown__filter-string-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border: none;
  outline: none;
  padding: 0;
  font-family: $font-family;
  font-size: calcSize(2);
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  line-height: calcSize(3);
  appearance: none;
}
.sd-dropdown--empty:not(.sd-input--disabled) .sd-dropdown__filter-string-input::placeholder {
  color: $foreground-light;
}
.sd-dropdown__filter-string-input::placeholder {
  font-family: $font-family;
  font-size: calcSize(2);
  color: $foreground;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  line-height: calcSize(3);
  appearance: none;
}
