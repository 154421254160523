.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #36393e !important;
  border-color:  #36393e !important;
}

.sidebar-button {
  background: #36393e;
  border: 0px solid white;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 230px;
  text-align: left;
  margin-left: 10px;
  font-size: 18px;
}

.sidebar-button:hover {
  background: #5E6064;
}

.sidebar {
  background-color: #36393e;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidebar a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: hsl(237, 68%, 65%);
  display: block;
}

body {
  overscroll-behavior-y: none;
}

.sidebar a:hover {
  color: #fff;
  background-color: #36393e;
}

.main {
  margin-left: 200px; /* Set the margin to the width of the sidebar */
  padding: 0px 10px;
}

#chat2 .btn-flat {
  background-color: #36393e;
  color: white;
}

#chat2 button:focus { 
  box-shadow: none;
}

#chat2 .inner
{
    display: inline-block;
}

#chat2 .dropdown-item {
  font-weight: 300;
  font-size: 11pt;
}

#chat2 .dropdown-item.active,
#chat2 .dropdown-item:active {
  background-color: #36393e;
  color: #fff;
}

#chat2 .dropdown-toggle::after { 
  content: none; 
  }

.menu {
  position: absolute;

  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  border: 1px solid grey;
  width: 150px;
}

.menu > li {
  margin: 0;

  background-color: white;
}

.menu > li:hover {
  background-color: lightgray;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

#chat2 .form-control {
  border-color: transparent;
  }
  

#chat2 .form-control:focus {
border-color: transparent;
box-shadow: inset 0px 0px 0px 1px transparent;
}

.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}

#chat2 .nobull{
  list-style-type: none;
}

#chat2 .adapt{
  height: 100vh;
  width: 100vw;
  background: #eee;
}

#chat2 input[type="text"]
{
    font-size:16px;
}

#chat2 input:focus {
  position:fixed;
  bottom: 0;
  width: calc(100% - 12px);
}

#chat2 .buttonspace {
    margin-left :10px 
}

#chat2 .noborder{
  padding: 0;
  border: none;
  background: none;
}

/* #chat2 .posbutton{
  position: relative;
  right: 0px;
  top: -20px;
} */

#chat2 .myfont{
  font: 300 0.95rem/1.75rem "Open Sans", sans-serif
}

#chat2 textarea {
  resize: none;
  font-size:16px;
}

#chat2 .rounded1{
  border-radius: 15px;
}

#chat2 .rounded2{
  border-radius: 10px;
}

#chat2 .message-list{
  margin-left: 0;
}

#chat2 .logo img {
	max-height: 40px;
	margin-right: 10px;

	display: inline-block;
  }

#chat2 .logo {
	font-size: 30px;
	margin-top: 1px;
	/* margin-left: -7.5px; */
	padding: 0;
	line-height: 1;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
	text-decoration: none;
  }



#chat2 .h1title {
	font-family: "Lexend";
	font-size: 22px;
	line-height: 1;
	font-weight: 300;
	letter-spacing: 1px;
	color: #fff;
	align-items: left;
	vertical-align: middle;
	text-decoration: none;
  }


#chat2 .h1titlemobile {
	font-family: "Quicksand";
	font-size: 1.5rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: 1px;
	color: #fff;
	align-items: left;
	vertical-align: middle;
	text-decoration: none;
  }

  #chat2 .menufont {
    font-family: "Quicksand";
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 0.0px;
    color: #fff;
    align-items: left;
    vertical-align: middle;
    text-decoration: none;
    }




.typing {
  width: 4.5em;
  height: 2em;
  position: relative;
  padding: 12px;
  margin-left: -20px;
  background: #f5f6f7;
  border-radius: 15px;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}


.Toastify__toast-container--top-center {
  top: 45vh;
  left: 50%; 
  transform: translate(-50%, -50%);
}

.Toastify__toast {
  border-radius: 10px;
  font-size: 15px;
  padding: 15px;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #36393e;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 480px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: "Open Sans";
  --toastify-text-height: 10px;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;


  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;


  --toastify-color-progress-light:  #36393e;

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}